const startDate = new Date("2023-11-16");

// Get the user's timezone offset
const userTimezoneOffset = new Date().getTimezoneOffset() * -1 * 60000;
const timezoneAdjustedStartDate = new Date(startDate.getTime() + userTimezoneOffset);
const timezoneAdjustedToday = new Date(Date.now());

// Round down timezoneAdjustedToday to the start of the day
timezoneAdjustedToday.setHours(0, 0, 0, 0);

const timeDifference = timezoneAdjustedToday.getTime() - timezoneAdjustedStartDate.getTime();
const daysPassed = Math.floor(timeDifference / (1000 * 3600 * 24));

const currentStoryIndex = 1 + daysPassed;

export const getCurrentStory = () => stories[currentStoryIndex];

// If the forced ID doesn't exist fallback to story of the day
export const getStoryById = (id) => stories?.[id] || getCurrentStory();


export const stories = {
    1: {
        title: "SCP-G1116",
        content: `<div id='page-content'>
        <p><strong>Item #:</strong> SCP-G1116
            <br><br>
        </p>
        <p><strong>Object Class:</strong> Euclid<br><br></p>
        <p><strong>Special Containment Procedures:</strong> Item SCP-G1116 should be contained within a hermetically sealed
            containment chamber measuring at least 6 m x 6 m x 6 m, located at Site-██. The chamber should be equipped with
            a series of environmental control systems capable of rapidly altering temperature, humidity, and atmospheric
            composition, as per Research Command's specifications. The containment chamber walls must be comprised of
            reinforced steel with a thickness of no less than 30 cm, and should be lined with a secondary inner layer of
            lead to prevent any potential electromagnetic or psychic emissions from escaping the containment area. Access to
            SCP-G1116's containment chamber is to be restricted to personnel with Level-4 clearance or higher, and only
            after prior approval by at least two Level-4 researchers. All personnel entering the containment area must wear
            specialized protective suits designed to shield against SCP-G1116's anomalous effects, and must undergo a full
            psychological evaluation upon exiting the chamber. Any equipment introduced into the containment chamber must be
            non-organic and disposable, to prevent SCP-G1116 from using materials to breach containment or propagate its
            effects. In the event of a containment breach, Site-██ is to initiate Protocol Epsilon-11, necessitating an
            immediate lockdown and deployment of the Rapid Response Team to recontain the anomaly.<br><br></p>
        <p><strong>Description:</strong> SCP-G1116 is a sentient conglomerate of metallic shards, with a highly magnetic
            core that exhibits telekinetic and telepathic abilities. Its size and shape are inconsistent, as it can draw
            metal objects towards itself to increase its mass and alter its form. This entity demonstrates intelligence and
            awareness of its surroundings, and it has the ability to communicate through unknown means, usually manifesting
            as auditory hallucinations perceived by nearby individuals. SCP-G1116 exhibits a hostility toward living
            organisms and has shown the capability to manipulate metallic objects to create constructs which it then
            utilizes for offensive purposes. It can also induce psychological disturbances in humans, such as intense
            paranoia, hallucinations, and in extreme cases, cerebral hemorrhage. Any changes in SCP-G1116's behavior or
            increase in activity suggest that it may be preparing to breach containment or that it has developed a new
            method of influence, necessitating increased vigilance by containment personnel. The reason behind SCP-G1116's
            hostility and the full extent of its abilities remain subjects of ongoing research.<br><br></p><img
            src='scp_images/SCP-G1116.png' alt='SCP'><br><br>
        <p><strong>Testing And Research:</strong> SCP-G1116's telekinetic and telepathic properties have been of
            considerable interest to the Foundation's research departments. Experimentation has primarily focused on
            determining the limits of its telekinetic strength, the range of its telepathic communication, and the
            mechanisms by which it influences the cerebral activity of humans. Special attention is given to understanding
            how SCP-G1116 manipulates metallic objects to create constructs, a process that appears to involve not only
            telekinesis but some form of rapid molecular bonding. Various tests have been conducted using different alloys,
            magnetic field strengths, and environmental conditions to establish a pattern to SCP-G1116's behavior and to
            identify potential weaknesses. Efforts to establish communication have been cautious due to the entity's hostile
            nature and the risk of inducing psychological trauma in personnel. Currently, research is being carried out to
            develop a way to safely interact with SCP-G1116 without provoking an aggressive response, with the ultimate goal
            of understanding its origin, motivations, and potential for cooperation or pacification.<br><br></p>
        <p><strong>Incident Reports:</strong> Containment breach involving SCP-G1116 where it manages to assemble a
            rudimentary humanoid form out of metal objects. During this incident, several personnel experience intense
            hallucinations leading to a temporary loss of sanity, and SCP-G1116 attempts communication through auditory
            hallucinations with the Rapid Response Team. The breach is contained, but questions arise about SCP-G1116's
            potential understanding of human psychology and its possible goal to manipulate its containment.<br><br></p>
        <p><strong>Risk Designation:</strong> SCP-G1116's risk designation is based on its capability to exert telekinetic
            and telepathic influence, as well as its hostility towards humans and tendency to hijack metal objects to create
            constructs for offensive purposes. The anomaly represents a significant cognitive hazard due to its ability to
            induce psychological disturbances and potentially lethal cerebral effects. SCP-G1116's unpredictability combined
            with its known behaviors indicates a high risk to the mental health and physical safety of personnel, requiring
            stringent containment measures and emergency protocols in case of its increased activity or attempted
            containment breach.<br><br></p>
        <p><strong>Log:</strong> During a routine containment inspection of SCP-G1116, the anomaly unexpectedly initiated a
            telekinetic assault, causing a cascade of metallic objects to levitate and converge upon the inspecting
            personnel. The intense magnetic field generated by SCP-G1116 resulted in electronic disruption and equipment
            malfunctions within the containment chamber. The inspecting team reported experiencing a sudden onset of
            auditory hallucinations, which attempted to coerce them into breaching the containment protocols. The quick
            intervention of the control room operators, who enacted an emergency environmental shift, destabilized
            SCP-G1116's telekinetic control and caused the metallic objects to drop, thereby neutralizing the immediate
            threat and facilitating the team's retreat. Subsequent to the event, all affected personnel underwent
            psychological assessment and treatment for minor auditory trauma.<br><br></p>
    </div>`,
    },
    2: {
        title: "SCP-G1117",
        content: `<div id='page-content'>
        <p><strong>Item #:</strong> SCP-G1117
            <br><br>
        </p>
        <p><strong>Object Class:</strong> Euclid<br><br></p>
        <p><strong>Special Containment Procedures:</strong> SCP-G1117 is to be contained in a 5m x 5m cell with walls coated
            in a reflective material resistant to high temperatures. The cell must be equipped with no fewer than three (3)
            infrared cameras for constant surveillance. Personnel entering SCP-G1117's containment area are required to wear
            heat-resistant suits and are not permitted to engage in direct visual contact with the entity. All interactions
            with SCP-G1117 must be conducted via the installed one-way intercom system. Containment personnel must monitor
            the SCP for any signs of increased activity or aggression. In the case of a containment breach, Site-77's
            response team must deploy silver iodide aerosol canisters to create an artificial cloud cover, reducing
            SCP-G1117's energy absorption capacity and facilitating its recapture.<br><br></p>
        <p><strong>Description:</strong> SCP-G1117 is a humanoid entity approximately 2.3 meters in height with dermal
            tissue that exhibits extraordinary solar energy absorption capabilities, effectively converting sunlight into
            intense heat. The entity has the ability to radiate this stored heat in controlled bursts of varying intensity,
            potentially causing severe thermal damage to its surroundings. SCP-G1117 demonstrates a sentient level of
            intelligence but exhibits no desire to communicate with Foundation personnel beyond basic needs and requests.
            During periods of extended sunlight exposure, SCP-G1117's core temperature has been observed to spike
            dangerously, coinciding with increases in aggression and energy discharge. SCP-G1117's energy emission spectrum
            has been recorded to exceed temperatures of 1500 degrees Celsius in concentrated bursts.<br><br></p><img
            src='scp_images/SCP-G1117.png' alt='SCP'><br><br>
        <p><strong>Testing And Research:</strong> SCP-G1117 has shown remarkable solar energy absorption and thermal
            emission capabilities. Initial containment included standard procedures until Incident G1117-Alpha, when
            SCP-G1117's energy output briefly overwhelmed the containment unit's temperature regulation systems. Following
            this event, testing shifted towards understanding the limits of SCP-G1117's abilities and devising more
            effective containment protocols. Recent experiments have involved controlled exposure to varied intensities of
            artificial sunlight to elicit and measure SCP-G1117's thermal output. Thermal imaging cameras are used to track
            heat distribution across the entity's body and heat resistance tests on materials for containment purposes are
            ongoing. Comprehensive psych evaluations have been attempted to evaluate SCP-G1117's cognitive capacities and
            behavioral patterns but have been met with minimal cooperation from the SCP. Interactions are carefully scripted
            to minimize the risk of provocation, and the entity's responses to various stimuli, including social
            interactions, are meticulously logged for analysis. Data from these sessions contribute to a broader
            understanding of SCP-G1117's behavioral ecology and social needs.<br><br></p>
        <p><strong>Incident Reports:</strong> During a routine test session, SCP-G1117 breached containment due to an
            unprecedented surge in heat emission that compromised the reflective coating of the containment chamber. This
            event, hereby designated Incident G1117-Beta, resulted in third-degree burns to three facility personnel and
            caused substantial damage to the infrastructure within Section 4-C. The response team implemented the emergency
            contingency protocol involving silver iodide aerosol deployment, which successfully induced cloud formation and
            reduced SCP-G1117's energy absorption capacity. However, SCP-G1117 utilized its thermal emission in a bid to
            counteract the artificial cloud cover, necessitating additional measures to ensure containment. The incident
            highlighted vulnerabilities in both containment protocols and emergency response procedures, leading to a series
            of revisions aimed at enhancing the safety and integrity of the containment facility.<br><br></p>
        <p><strong>Risk Designation:</strong> SCP-G1117 exhibits extreme thermal emission, spiking in aggression with
            increased solar exposure, and poses a severe risk to personnel and containment integrity with heat emissions
            exceeding 1500 degrees Celsius.<br><br></p>
        <p><strong>Log:</strong> Routine check-up on SCP-G1117 escalated into an emergency situation when solar absorption
            exceeded projected parameters, resulting in a sudden discharge of thermal energy that overwhelmed the
            containment unit's defenses.<br><br></p>
    </div>`,
    },
    3: {
        title: "",
        content: ``,
    },
    4: {
        title: "",
        content: ``,
    },
    5: {
        title: "",
        content: ``,
    },
    6: {
        title: "",
        content: ``,
    },
    7: {
        title: "",
        content: ``,
    },
    8: {
        title: "",
        content: ``,
    },
    9: {
        title: "",
        content: ``,
    },
    10: {
        title: "",
        content: ``,
    },
    11: {
        title: "",
        content: ``,
    },
    12: {
        title: "",
        content: ``,
    },
    13: {
        title: "",
        content: ``,
    },
    14: {
        title: "",
        content: ``,
    },
    15: {
        title: "",
        content: ``,
    },
    16: {
        title: "",
        content: ``,
    },
    17: {
        title: "",
        content: ``,
    },
    18: {
        title: "",
        content: ``,
    },
    19: {
        title: "",
        content: ``,
    },
    20: {
        title: "",
        content: ``,
    },
    21: {
        title: "",
        content: ``,
    },
    22: {
        title: "",
        content: ``,
    },
    23: {
        title: "",
        content: ``,
    },
    24: {
        title: "",
        content: ``,
    },
    25: {
        title: "",
        content: ``,
    },
    26: {
        title: "",
        content: ``,
    },
    27: {
        title: "",
        content: ``,
    },
    28: {
        title: "",
        content: ``,
    },
    29: {
        title: "",
        content: ``,
    },
    30: {
        title: "",
        content: ``,
    },
    31: {
        title: "",
        content: ``,
    },
    32: {
        title: "",
        content: ``,
    },
    33: {
        title: "",
        content: ``,
    },
    34: {
        title: "",
        content: ``,
    },
    35: {
        title: "",
        content: ``,
    },
    36: {
        title: "",
        content: ``,
    },
    37: {
        title: "",
        content: ``,
    },
    38: {
        title: "",
        content: ``,
    },
    39: {
        title: "",
        content: ``,
    },
    40: {
        title: "",
        content: ``,
    },
    41: {
        title: "",
        content: ``,
    },
    42: {
        title: "",
        content: ``,
    },
    43: {
        title: "",
        content: ``,
    },
    44: {
        title: "",
        content: ``,
    },
    45: {
        title: "",
        content: ``,
    },
    46: {
        title: "",
        content: ``,
    },
    47: {
        title: "",
        content: ``,
    },
    48: {
        title: "",
        content: ``,
    },
    49: {
        title: "",
        content: ``,
    },
    50: {
        title: "",
        content: ``,
    },

};