import { useEffect, useState } from "react";
import { ScpContent } from "./ScpContent";
import { getCurrentStory, getStoryById } from "./testScpContent";

export function ScpContainer() {
  const [story, setStory] = useState(getCurrentStory());

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const forcedId = params.get("forced");
    if (forcedId) {
      const forcedStory = getStoryById(forcedId);
      setStory(forcedStory);
    }
  }, [setStory]);

  const { content, title } = story;

  return <ScpContent storyHtml={content} storyTitle={title} />;
}
