export function ScpContent({ storyHtml, storyTitle }) {
  return (
    <div>
      <style type="text/css" id="internal-style" dangerouslySetInnerHTML={{ __html: "/* theme */\n                    @import url(https://d3g0gp89917ko0.cloudfront.net/v--291054f06006/common--theme/base/css/style.css);\n                    @import url(https://scpwiki.github.io/sigma/css/sigma.min.css);\n            " }} />
      <div id="skrollr-body">
        <div id="container-wrap-wrap">
          <div id="container-wrap">
            <div id="container">
              <div id="header">
                <h1><a href="/"><span>Chat SCP</span></a></h1>
                <h2><span>Secure, Contain, Protect</span></h2>
              </div>
              <div id="content-wrap">
                <div id="main-content" style={{ margin: 24 }}>
                  <div id="action-area-top" />
                  <div id="page-title">
                    {storyTitle}
                  </div>
                  <div id="page-content">
                    <div dangerouslySetInnerHTML={{ __html: storyHtml }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}